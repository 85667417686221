.footer-container {
    background: url('/public/images/GrayBackground.png')
    center center/cover no-repeat;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container > h1 {
    color: #fff;
}

@media screen and (max-width: 768px) {
    .footer-container > h1 {
        font-size: 20px;
    }
}