.about-container {
    background: url('/public/images/BluePaint.png')
    left/auto 100% repeat-x;
    width: 100%;
    height: 80vh;
    min-height: 650px;
    display: flex;
    justify-content: center;
}

.background-img {
    background: url('/public/images/BlurPaintBrush.png')
    center center/cover no-repeat;
    position: relative;
    width: 1240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    object-fit: contain;
}

.background-img > h1 {
    font-size: 80px;
    align-self: flex-start;
    padding: 0 20px;
}

.background-img > h2 {
    font-size: 70px;
    padding: 100px 100px 20px 100px;
}

@media screen and (max-width: 960px) {
    .background-img > h1 {
        font-size: 70px;
    }

    .background-img > h2 {
        font-size: 60px;
        padding: 50px 50px 10px 50px;
    }
}

@media screen and (max-width: 768px) {
    .background-img > h1 {
        font-size: 60px;
        padding: 0 10px;
    }

    .background-img > h2 {
        font-size: 45px;
        padding: 10px 30px 0 30px;
    }
}