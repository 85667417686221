.service-container{
    background: #efecdd;
    height: 80vh;
    min-height: 600px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    
}

.cards {
    padding: 4rem;
    width: 1240px;
    height: 100%;
    background: #fff;
  }
  
  h1 {
    text-align: left;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    height: 100%;
  }
  
  .cards__items {
    height: 100%;
    width: 90%;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 20px;
    border-radius: 10px;
  }
  
  .cards__item__container {
    display: flex;
    flex-flow: column;
    justify-content: end;
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__img {
    position: absolute;
    max-height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1) translateX(-45%);
  }
  
  .cards__item__info {
    pointer-events: none;
    padding: 20px 30px 30px;
    z-index: 2;
  }
  
  .cards__item__text {
    color: #fff;
    font-size: 18px;
    line-height: 50px;
  }
  
  @media screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .cards__items {
      display: flex;
      flex-direction: column;
    }

    .cards__item {
      margin-bottom: 2rem;
    }

    .cards__item__img {
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      object-fit: cover;
    }
  }